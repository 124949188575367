<template>
  <div>
    <apexchart type="donut" :options="chartOptions" :series="series"></apexchart>
  </div>
</template>

<script>
import Vue from "vue";
import VueApexCharts from "vue-apexcharts";
Vue.use(VueApexCharts);

Vue.component("apexchart", VueApexCharts);
export default {
  name: "Growth",
  components: {},
  data() {
    return {
      series: [10, 15, 26],
      chartOptions: {
        chart: {
          type: "donut",
        },
        plotOptions: {
          pie: {
            donut: {
              size: "65%"
            }
          }
        },
        dataLabels: {
          enabled: false
        },
        labels: ['Spot', 'Staking', 'Earning'],
        legend:{
          show:true,
          showForSingleSeries:true,
          onItemHover: {
          highlightDataSeries: true
      },
        }
      },
    };
  },
  
 
  
};
</script>
<style scoped>
@media only screen and (max-width: 767px) {

  .shadow-sm,
  .shadow-lg,
  .shadow {
    box-shadow: none !important;
  }
}
</style>