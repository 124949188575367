<template>
  <WalletLayout>
    <div class="row main-div g-4">
      <div class="col-lg-4 col-12 order-4 order-lg-1">
        <router-link to="/account/referral-friends">
          <img :src="require('@/assets/Landing_Page/banner_1.svg')" class="img-fluid rounded-4" alt="">
        </router-link>
      </div>
      <div class="col-lg-8 col-12 order-1 order-lg-2">
        <div class="card rounded-4 h-100">
          <div class="card-body">
            <AvailableBalance :data="balancesData" />
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-12 order-2 order-lg-3">
        <div class="card rounded-4 h-100">
          <div class="card-body">
            <div class="row align-items-center">
              <div class="col-7">
                <h6 class="title fw-600 fs-20 m-0">Statistics</h6>
              </div>
              <div class="col-5">
                <select class="form-select form-select-sm border-0">
                  <option value="today">Today</option>
                  <option selected value="last_month">Last Month</option>
                  <option value="last_three_month">3 Months</option>
                </select>
              </div>
              <div class="col-12 py-5">
                <Growth />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-8 col-12 order-3 order-lg-3">
        <div class="card rounded-4">
          <div class="card-body">
            <div class="row align-items-center gap-3 flex-column flex-lg-row">
              <div class="col">
                <h6 class="title fw-600 fs-20 m-0">Balances</h6>
              </div>
              <div class="col-lg-4 col">
                <div class="d-flex gap-2">
                  <div class="input-group search-input">
                    <span class="input-group-text px-2"><i class="fa-solid fa-magnifying-glass"></i></span>
                    <input type="search" v-model="search" ref="searchInput" v-on:keyup="searchData()" placeholder="Search"
                      class="form-control ">
                  </div>
                </div>
              </div>
              <div class="col-12">
                <BalancesTableList :data="holdings" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <WithdrawModal/>
    </div>
  </WalletLayout>
</template>

<script>
import WithdrawModal from "@/components/modal/Withdraw.vue";
import ApiClass from "@/api/api";
import WalletLayout from "@/layouts/WalletLayout.vue";
import AvailableBalance from "@/components/Wallet/AvailableBalance.vue";
// import Transaction from "@/components/Wallet/Overview/Transaction.vue";
// import TransactionComponent from "@/components/Wallet/TransactionComponent.vue";
import BalancesTableList from "@/components/Wallet/BalancesTableList.vue";
import Growth from "@/components/Wallet/Overview/Growth.vue";
// import BuyCrypto from "@/components/Payment/BuyCrypto.vue";
import exactMath from "exact-math";

export default {
  name: 'overview',
  components: {
    // Balances,
    AvailableBalance,
    WithdrawModal,
    // Transaction,
    // TransactionComponent,
    // BuyCrypto,
    Growth,
    BalancesTableList,
    WalletLayout,
  },
  data() {
    return {
      balancesData: {
        heading: 'Overview',
        subheading: 'Track funds in your account',
        // buttons: [
        //   { name: 'Withdraw', link: '/wallet/withdraw', class: 'text-primary border-primary' },
        //   { name: 'Deposit', link: '/wallet/deposit', class: 'btn-primary text-white' }
        // ],
        availableQty: 0,
        availableBal: 0,
        cards: [
          { name: 'Total Balance', icon: 'deposit.svg', link: '/wallet/spot', qty: 0, bal: 0 },
          { name: 'Spot', icon: 'deposit.svg', link: '/wallet/spot', qty: 0, bal: 0 },
          { name: 'Earn', icon: 'deposit_up.svg', link: '/wallet/staking', qty: 0, bal: 0 },
          // { name: 'Total Freeze', icon: 'deposit.svg', link: '/coming', qty: 0, bal: 0 },
        ],
      },
      holdings: {
        type: 'spot',
        skeletonLoad: true,
        res: [],
        balances: [],
        tableHeading: ['Coin', 'Spot', 'Earn', 'Total', 'Action'],
      },
      themeColor: 0,
      updateRes: [],
      res: [],
      loading: true,
      search: "",
    }
  },
  async mounted() {
    await this.callApi();
    this.themeColor = this.$store.getters.getTheme;
  },
  computed: {
    // total_currency_get() {
    //   // this.wallet[0].qty += item.quantity ? parseFloat(item.quantity) : 0;
    //   //     this.wallet[0].bal += item.c_bal ? parseFloat(item.c_bal) : 0;

    //   let total = 0;
    //   this.updateRes?.map((el) => {
    //     total = exactMath.add(total, el.el.quantity);
    //   });
    //   return `${total.toFixed(4)}`;
    // },
  },
  methods: {
    searchData() {
      var search = this.search.toUpperCase();
      if (search) {
        this.holdings.balances = this.updateRes.filter((e) => e.symbol.includes(search) || e.currency_type.includes(search.toLowerCase()));
      } else {
        this.holdings.balances = this.updateRes.filter((e) => e);
        // this.searchZeroData();
      }
    },
    async callApi() {
      this.res = await ApiClass.getNodeRequest("user-crypto/get", true);
      if (this.res.data.status_code == 1) {
        this.loading = false;
        this.holdings.skeletonLoad = false;

        for (const item of this.res.data.data) {
          // this.balancesData.availableQty += item.quantity ? parseFloat(item.quantity) : 0;
          // this.balancesData.availableBal += item.c_bal ? parseFloat(item.c_bal) : 0;
          this.balancesData.cards[1].qty += item.quantity ? parseFloat(item.quantity) : 0;
          this.balancesData.cards[1].bal += item.c_bal ? parseFloat(item.c_bal) : 0;
          this.balancesData.cards[2].qty += item.stake_quantity ? parseFloat(item.stake_quantity) : 0;
          this.balancesData.cards[2].bal += item.stake_bal ? parseFloat(item.stake_bal) : 0;
          // this.balancesData.cards[3].qty += item.fund_quantity ? parseFloat(item.fund_quantity) : 0;
          // this.balancesData.cards[3].bal += item.fund_bal ? parseFloat(item.fund_bal) : 0;
        }

        this.balancesData.cards?.map((el) => {
          this.balancesData.availableQty = exactMath.add(this.balancesData.availableQty, el.qty);
          this.balancesData.availableBal = exactMath.add(this.balancesData.availableBal, el.bal);
        });

        this.balancesData.cards[0].qty = this.balancesData.availableQty;
        this.balancesData.cards[0].bal = this.balancesData.availableBal;

        this.holdings.balances = this.updateRes = this.res.data.data;
        // alert(JSON.stringify(this.holdings.res.data.data))
        // this.total_freezed = this.holdings.res.data.freezedTotal;
        // this.updateBalance(this.holdings.balances);
      }
    },
  },
  watch: {
    "$store.getters.getTheme": function () {
      this.themeColor = this.$store.getters.getTheme;
    },
  },
}
</script>
<style scoped>
.wallet-box {
  background-color: var(--bg5);
}

/* .table-height{
  height: 100%;
  max-height: 10rem;
  overflow-y: scroll;
  overflow-x: hidden;
} */

.wallet-box .wallet_user {
  font-size: 26px;
}

@media only screen and (max-width: 767px) {
  .wallet-box .wallet_user {
    font-size: 24px;
  }

  .shadow-sm,
  .shadow-lg,
  .shadow {
    box-shadow: none !important;
  }
}
</style>