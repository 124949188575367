<template>
  <div>
    <div class="modal" id="withdraw-modal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog card-tm modal-dialog-centered">
        <div class="modal-content card">
          <div class="modal-header border-0">
            <h5 class="modal-title">Withdraw Crypto</h5>
            <button type="button" class="btn close-btn" @click="close" data-bs-dismiss="modal" aria-label="Close"
              ref="Close">
              <i class="fas fa-times"></i>
            </button>
          </div>
          <div class="modal-body p-4">
            <div class="col-md-12">
              <div class="row g-3">
                <div class="col-6">
                  <label class="fs-14 pb-2 fw-500">Select Coin</label>
                  <v-select class="border-0" v-on:input="assignSymbol($event.title)" v-model="selected" :options="pairs"
                    label="title">
                    <template slot="selected-option" slot-scope="option">
                      <div class="d-flex align-items-center">
                        <img v-if="selected.img" height="20" v-bind:src="option.img">
                        <img v-else height="20" v-bind:src="coin_img" />
                        <span style="position: relative; margin: 0px 5px">{{ option.title }}</span>
                      </div>
                    </template>
                    <template v-slot:option="option">
                      <img :src="option.img" style="width:20px; margin-right:8px;">{{ option.title }}
                    </template>
                  </v-select>
                </div>
                <div v-if="loading" class="col-12 text-center mb-2">
                  <div class="spinner-border" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </div>
                <div class="col-6" v-if="dataVisibleWithdraw">
                  <label class="fs-14 pb-2 fw-500">Select Network</label>
                  <v-select :clearable="false" id="token" label="token_type" v-model="form.token_type" :searchable="false"
                    v-on:input="alterTokenName($event)" :reduce="(option) => option.token_type"
                    :options="withdraw_net"></v-select>
                </div>
                <div class="col-12" v-if="dataVisibleWithdraw">
                  <label class="fs-14 pb-2 fw-500">Destination Address</label>
                  <div class="form-main-box" :class="{
                    'form-group--error': $v.form.toAddress.$error
                  }">
                    <input type="text" class="form-control" id="formGroupExampleInput" placeholder="Enter Wallet Address"
                      autocomplete="off" v-model.trim="$v.form.toAddress.$model" />

                    <div class="error" v-if="
                      submitted && !$v.form.toAddress.required
                    ">
                      Destination is required.
                    </div>
                  </div>
                  <div>
                    <span class="text-primary fs-12 pb-2 fw-500">Transaction Fees:</span>
                    <span class="fw-500 fs-12"> {{ this.withdraw_commission }}
                      <span v-if="withdraw_commission_per">%</span></span>
                  </div>
                </div>
                <div class="col-12" v-if="dataVisibleWithdraw">
                  <label class="fs-14 pb-2 fw-500">Amount</label>
                  <input type="text"
                    onkeypress="return (event.charCode !=8 && ((event.charCode >= 48 && event.charCode <= 57) || (event.charCode == 46 && this.value.indexOf('.') == -1)))"
                    onpaste="return false;" class="form-control" id="formGroupExampleInput" placeholder="Amount"
                    v-model.trim="$v.form.amount.$model" autocomplete="off" />
                  <div class="error text-start" v-if="submitted && !$v.form.amount.required">
                    Amount is required.
                  </div>
                  <div class="error text-start" v-if="submitted && between">
                    Amount should be less or equal to your
                    balance
                  </div>
                  <div class="text-start">
                    <span class="fw-500 fs-12"> {{ this.withdrawModal.balance }} </span>
                    <span class="text-primary fs-12 pb-2 fw-500"> {{ this.withdrawModal.itemCurrrency }}</span>
                  </div>
                </div>
                <div class="withdraw-btn-box py-2" v-if="dataVisibleWithdraw">
                  <button v-if="withdraw_loading" type="button" class="btn btn-primary main-btn"
                    data-bs-target="#proceedwithdrawl">
                    <div class="spinner-border text-light" role="status"></div>
                  </button>
                  <button v-else type="button" class="btn btn-primary p-2 w-100 main-btn"
                    data-bs-target="#proceedwithdrawl" @click="submitWithdrawal">
                    Proceed Withdraw
                  </button>
                </div>
                <div v-else class=" alert alert-warning text-center">
                  <span class="">{{ this.withdrawModal.message }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "vue-select/dist/vue-select.css";
// import { Modal } from "bootstrap";
import ApiClass from "@/api/api";
import { required } from "vuelidate/lib/validators";

export default {
  name: "Withdraw",
  data() {
    return {
      // theModal: null,
      selected: "Choose Coin",
      coin_img: null,
      pairs: [],
      withdraw_loading: false,
      form: {
        // token_types: [],
        token_type: "",
        amount: "",
        toAddress: "",
        currency: "",
      },
      withdraw_commission: "",
      withdraw_commission_per: false,
      withdraw_type: "",
      currency_networks: [],
      withdraw_net: [],
      showData: [],
      withdrawl_data: "",
      submitted: false,
      between: false,
      withdrawModal: {
        itemCurrrency: "",
        itemWithdraw: "",
        message: "",
        token_type: "",
        balance: "",
        toAddress: "",
      },

      base_currency: [],
      other_currency: [],
      total_currency: "",
      response: [],
      loading: true,
      showData1: [],
      dataVisibleWithdraw: false,
      total_freezed: null
    };
  },
  validations: {
    form: {
        toAddress: {
          required,
        },
        amount: {
          required,
        },
      },
  },
  async mounted() {

    this.selected = 'USDT';
    await this.assignSymbol(this.selected);
    await this.callApi();
  },
  methods: {
    close() {
      this.form.amount = this.form.toAddress = null;
      this.$refs.Close.click();
    },
    alterTokenName(token_type) {
      if ((this.withdrawModal.itemCurrrency == 'ETH') &&
        token_type == 'SELF') {
        return 'ERC20';
      }
      if ((this.withdrawModal.itemCurrrency == 'TRX') &&
        token_type == 'SELF') {
        return 'TRC20';
      }
      if ((this.withdrawModal.itemCurrrency == 'MCOIN') &&
        token_type == 'SELF') {
        return 'MCOIN20';
      }
      return token_type;

    },
    networkInfo(tType = null) {
      tType = tType?.substring(0, 3);
      if (tType == "ERC") return { name: "Ethereum", short: "ERC" };
      else if (tType == "TRC") return { name: "Tron", short: "TRC" };
      else if (tType == "BEP") return { name: "Binance", short: "BEP" };
      else if (tType == 'SEL' && this.depositModal.itemCurrrency == "MCOIN") return { name: "MCOIN", short: "MCOIN", token_type: "MCOIN20" };
      else if (tType == 'SEL' && this.depositModal.itemCurrrency != "MCOIN") {
        return (this.depositModal.itemCurrrency == 'ETH') ? { name: "Ethereum", short: "ERC", token_type: "ERC20" } : { name: "Tron", short: "TRC", token_type: "TRC20" };
      }
      else return { name: tType, short: tType };
    },
    async assignSymbol(event) {
      if (event != this.$route.query.symbol) {
        this.$router.push({ path: this.$route.fullPath, query: { symbol: event } });

      }
      await this.callApi(event);
      this.withdraw(this.other_currency[0]);

    },

    assignWithdraw(data = null) {
      // console.log("withdraw======", data);
      this.showData1 = data;
      this.submitted = false;
      this.withdraw_commission_per = (data.type == "percentage") ? true : false;
      this.withdraw_commission = data.withdraw_commission;
      this.form.token_type = data.token_type;
      this.form.amount = this.form.toAddress = "";
    },
    async callApi(symbol = null) {
      this.response = await ApiClass.getNodeRequest("user-crypto/get", true);
      if (this.response.data.status_code == 1) {
        this.loading = false;
        this.base_currency = this.response.data.data.filter((e) => {
          if (e.currency_type == "fiat" && e.symbol == symbol) {
            return e;
          }
        });

        this.other_currency = this.response.data.data.filter((e) => {
          if (e.currency_type == "crypto" && e.symbol == symbol) {
            return e;
          }
        });

        this.response.data.data.filter((e) => {
          if (e.symbol == symbol) {
            this.coin_img = e.image;
          }
          if (e.withdraw_enable == true) {
            this.pairs.push({ "title": e.symbol, 'img': e.image });
            this.pairs = this.$helpers.getUniqArrObj(this.pairs)
          }
        });

        this.total_currency = this.response.data.mainTotal;
        this.total_freezed = this.response.data.freezedTotal;

      }
      // console.log(this.response);
    },

    withdraw(item) {
      this.withdrawModal.itemCurrrency = item.symbol;
      this.withdrawModal.itemWithdraw = item.withdraw_enable;
      this.withdrawModal.message = item.withdraw_desc;
      this.form.currency = item.symbol;
      this.dataVisibleWithdraw = item.withdraw_enable;
      this.withdrawModal.balance = item.quantity;

      if (this.dataVisibleWithdraw) {
        this.withdraw_net = item.currency_networks.filter(
          (e) => e.withdraw_enable == true
        );

        if (this.withdraw_net.length != 0) {
          this.showData1 = this.withdraw_net[0];
          //  console.log(this.showData1);
          this.withdrawModal.toAddress = this.withdraw_net[0].address;
          this.form.token_type = this.withdraw_net[0].token_type;
          this.withdraw_commission_per = this.withdraw_net[0].type == 'percentage' ? true : false;
          this.withdraw_commission = this.withdraw_net[0].withdraw_commission;
          //   console.log(this.form);
          // this.form.token_types = abc.map((e) => {
          //   if (e.withdraw_enable) {
          //     return e.token_type;
          //   }
          // });
          // this.form.token_type = this.form.token_types[0];
          this.withdrawl_data = item.currency_networks;
        } else {
          this.dataVisibleWithdraw = false;
        }
      }
    },

    async submitWithdrawal() {
      this.submitted = true;
      if (this.$v.form.$invalid) {
        return;
      }

      if (
        parseFloat(this.form.amount) > parseFloat(this.withdrawModal.balance) ||
        this.form.amount == "0"
      ) {
        this.between = true;
        return;
      }
      this.submitted = false;
      this.withdraw_loading = !this.withdraw_loading;
      // console.log("form =====", this.form);
      var result = await ApiClass.postRequest(
        "block/transfer",
        true,
        this.form
      );
      this.withdraw_loading = !this.withdraw_loading;
      if (result.data.status_code == 1) {
        this.form.amount = "";
        this.form.toAddress = "";
        this.$swal({
          toast: true,
          position: "top-end",
          icon: "success",
          title: result.data.message,
          showConfirmButton: false,
          timer: 3000,
        });
        return this.$router.push({
          name: "Verify",
          params: {
            data: result.data.data,
          },
        });
      } else {
        // this.loading = false;
        this.$swal({
          toast: true,
          position: "top-end",
          icon: "error",
          title: result.data.message,
          showConfirmButton: false,
          timer: 3000,
        });
      }
    },
  },
};
</script>

<style scoped>
.nav-pills .nav-link.active {
  border-color: var(--primary);
  background-color: transparent;
  color: var(--primary) !important;
}

.nav-pills .nav-link {
  border: 1px solid var(--bs-gray-400);
}

.deposit-crypto {
  min-height: 40vh;
}

.page-title .heading {
  margin-bottom: 0 !important;
  font-size: 24px !important;
}

.portfolio-transaction {
  padding: 0 !important;
}

.backbutton {
  display: none;
}

#deposit-modal ol {
  padding-left: 1rem;
}

#deposit-modal .list li {
  list-style: auto;
  font-size: 12px;
  line-height: 25px;
}

.label {
  font-weight: 500;
  color: #868686;
  font-size: 1rem;
}

.network-ul li button {
  background-color: #CC9A02 !important;
  color: white;
  font-weight: 600;
  padding: 0.5rem 3rem;
}

.network-ul li button:hover {
  background-color: #CC9A02 !important;
  color: white;
  font-weight: 600;
  padding: 0.5rem 3rem;
}

.usdt-div {
  background-color: var(--bg5);
}

.deposit-crypto .plan_active {
  position: relative;
}

.deposit-crypto .plan_active::after {
  content: "\f058";
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  font-family: 'FontAwesome';
  font-size: 18px;
  position: absolute;
  width: 0px;
  height: 0px;
  border-left: 16px solid transparent;
  right: 30px;
  top: 5px;
}

.usdt-div p {
  font-size: 13px;
}


.fa-circle-exclamation {
  color: rgb(255, 71, 71);
}

.warning-dis {
  font-size: 0.85rem;
}

th {
  color: var(--text-black);
}

.is_dark .nav-pills .nav-link.active {
  border: 1px solid var(--primary);
  color: #fff;
}

@media only screen and (min-width : 320px) {

  .network-ul li button {
    margin-bottom: 0.5rem !important;
  }


}

.modal-body {
  padding: 2rem;
}

/* .modal {
  background-color: #edf0f2bf;
} */

.modal {
  z-index: 9999;
}

.modal-header .close-btn {
  border-radius: 50%;
  padding: .2rem .6rem;
}

.modal-content {
  box-shadow: rgb(0 0 0 / 10%) 0px 4px 12px;
  border: none;
  border-radius: 3px;
}

.modal-header h5 {
  font-family: var(--font-family);
  font-size: 18px;
  font-weight: 500;
  text-transform: capitalize;
  /* color: rgb(72, 81, 86); */
  margin-bottom: 0;
}

.is_dark .modal-header button {
  color: white;
}

button#logout-blank {
  color: rgb(244, 67, 54);
  background: transparent;
  border-color: rgb(244, 67, 54);
  margin-right: 10px;
}

button.btn-close:focus {
  box-shadow: none;
  outline: none;
}

.modal-header {
  background: var(--bg5);
}
</style>
